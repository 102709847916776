<template>
  <div class="main-file-container">
    <div>
      <div @click="$emit('deleteFile', file.id)" class="container-delete">
        <img src="/files/trashcan.svg" alt="trashcan" />
      </div>
      <div class="container-file-icon">
        <img
          :src="`/files/${file.type.length > 3 ? 'doc' : file.type}.svg`"
          alt="file"
        />
      </div>
      <div class="container-name-file">{{ file.filename }}</div>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({}),
  props: {
    file: { type: Object },
  },
};
</script>

<style lang="scss" scoped>
.main-file-container {
  position: relative;
  width: 136px;
  height: 115px;
  transition: background-color 0.2s ease-in-out;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  .loading-plank {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(2px);
    z-index: 10;
  }
  .container-delete {
    opacity: 0;
    user-select: none;
    cursor: pointer;
    position: absolute;
    top: 4px;
    right: 4px;
    width: 32px;
    height: 32px;
    background-color: var(--app-white);
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: opacity 0.2s ease-in-out;
  }
  &:hover {
    background-color: var(--app-grey-2);
    .container-delete {
      opacity: 1;
    }
    .container-name-file {
      color: var(--app-black-text);
    }
  }

  .container-file-icon {
    cursor: pointer;
    position: absolute;
    top: 22px;
    left: 50%;
    transform: translateX(-50%);
  }
  .container-name-file {
    cursor: pointer;
    position: absolute;
    bottom: 4px;
    left: 50%;
    width: 112px;
    font-size: 13px;
    height: 42px;
    text-overflow: ellipsis;
    overflow: hidden;
    word-wrap: break-word;
    text-align: center;
    transform: translateX(-50%);
    color: var(--app-grey-text);
    transition: color 0.2s ease-in-out;
  }
}
</style>
