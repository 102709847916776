<template>
  <div
    @drop.prevent="takeDragFile"
    @dragenter.prevent="dragInner"
    @dragleave.prevent="dragInner"
    @dragover.prevent="dragOver"
    class="main-container-dd-file"
    :style="
      type === 'audio' && !localLinkFile
        ? 'width: 100%;'
        : type === 'audio' && localLinkFile
        ? 'width: 100%; height:fit-content;'
        : ''
    "
  >
    <div
      v-if="localLinkFile && type !== 'audio'"
      @click.stop="clearFile"
      class="delete-block"
    >
      <img src="/files/trashcan.svg" alt="trashcan" />
    </div>
    <div
      v-if="!localLinkFile"
      class="dd-area"
      @click="openPlaceFiles"
      :class="{ active: enteredDrag }"
      :style="type === 'audio' ? 'width: 100%;' : ''"
    >
      <v-icon
        :color="enteredDrag ? 'var(--app-blue)' : 'var(--app-light-grey-3)'"
        >mdi-image</v-icon
      >
      <div class="text-add" :class="{ active_text: enteredDrag }">
        {{ placeholder }}
      </div>
    </div>
    <img
      @click="playDialog(localLinkFile)"
      v-else-if="fileType === 'image'"
      :src="localLinkFile"
      alt="localimg"
      class="file-local"
    />
    <video
      @click="playDialog(localLinkFile)"
      v-else-if="fileType === 'video'"
      :src="localLinkFile"
      alt="localimg"
      class="file-local"
    ></video>
    <audio
      v-else-if="fileType === 'audio'"
      :src="localLinkFile"
      controls
      alt="localimg"
      class="file-local"
      style="height: 50px; margin-bottom: 12px"
    ></audio>
    <v-btn
      v-if="type === 'audio'"
      @click="openPlaceFiles"
      depressed
      style="width: 108px"
      class="button-white-blue"
    >
      Изменить
    </v-btn>
    <DialogVideo :src="dialogSrc" v-model="dialogVideo" />
    <DialogImage :src="dialogSrc" v-model="dialogImg" />
    <img
      @click="playDialog(localLinkFile)"
      src="/files/play.svg"
      alt=""
      class="icon-play"
      :style="fileType === 'video' ? 'opacity:1;' : 'opacity:0;'"
    />
    <input
      type="file"
      style="display: none"
      ref="inputFile"
      :accept="allowedTypes.map((type) => `${type}/*`).join(',')"
      @input="handleFilesFromInput"
    />
  </div>
</template>

<script>
import DialogVideo from "@/components/main/dialog/DialogVideo";
import DialogImage from "@/components/main/dialog/DialogImage";
export default {
  components: {
    DialogImage,
    DialogVideo,
  },
  props: {
    placeholder: { type: String, default: "Добавить фото" },
    allowedTypes: { type: Array, default: () => ["image", "video", "audio"] },
    type: String,
    value: {},
  },
  data() {
    return {
      dialogImg: false,
      dialogVideo: false,
      dialogSrc: null,
      addedFile: null,
      localLinkFile: null,
      enteredDrag: false,
      fileType: null,
    };
  },
  methods: {
    openPlaceFiles() {
      this.$refs.inputFile.click();
    },
    clearFile() {
      this.fileType = null;
      this.addedFile = null;
      this.dialogSrc = null;
      this.localLinkFile = null;
      this.$emit("input", null);
    },
    playDialog(src) {
      switch (this.fileType) {
        case "image":
          this.dialogImg = true;
          break;
        case "video":
          this.dialogVideo = true;
          break;
        default:
          break;
      }
      this.dialogSrc = src;
    },
    checkType(typeFile) {
      if (this.allowedTypes.includes(typeFile)) {
        this.fileType = typeFile;
        return true;
      }
      this.$store.commit("errorSet", true);
      this.$store.commit("errorTextSet", "Неверный формат файла");
      return false;
    },
    takeDragFile(event) {
      const files = event.dataTransfer.files; // Получаем файлы
      this.handleFiles(files); // Обрабатываем файлы
      this.enteredDrag = false;
    },
    dragOver() {
      this.enteredDrag = true;
    },
    dragInner(e) {
      this.enteredDrag = e.type === "dragenter";
    },
    handleFilesFromInput(event) {
      this.handleFiles(event.target.files);
    },
    async handleFiles(files) {
      //   for (let i = 0; i < files.length; i++) { //пока что логика только на 1 файл
      const fileElement = files[0];

      const needType = fileElement.type.slice(
        0,
        fileElement.type.lastIndexOf("/")
      );
      if (this.checkType(needType)) {
        this.addedFile = fileElement;
        this.localLinkFile = URL.createObjectURL(fileElement);
        this.$emit("input", this.addedFile);
        this.$emit("change");
      }
      //   }
    },
    updateData() {
      if (this.value) {
        if (typeof this.value === "object") {
          this.localLinkFile = URL.createObjectURL(this.value);
        } else {
          this.localLinkFile = this.value;
        }
        this.fileType = this.type;
      }
    },
  },
  mounted() {
    this.updateData();
  },
};
</script>
<style lang="scss" scoped>
.main-container-dd-file {
  position: relative;
  width: 216px;
  height: 108px;
  // margin-bottom: 24px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  .delete-block {
    opacity: 0;
    user-select: none;
    cursor: pointer;
    position: absolute;
    top: 4px;
    right: 4px;
    width: 32px;
    height: 32px;
    background-color: var(--app-white);
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: opacity 0.2s ease-in-out;
  }

  &:hover {
    .delete-block {
      opacity: 1;
      z-index: 1;
    }
  }

  .dd-area {
    width: 100%;
    height: 100%;
    border: 1px solid var(--app-separators);
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    transition: background-color, 0.2s linear;
    .text-add {
      color: var(--app-light-grey-3);
    }
  }

  .active {
    background-color: var(--app-blue-more-light);
    color: var(--app-blue);
    border: 2px dashed var(--app-blue);
  }

  .active_text {
    color: var(--app-blue);
  }

  .file-local {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .icon-play {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
</style>
