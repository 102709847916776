<template>
  <div>
    <div class="lesson-content" v-if="loading">
      <div class="lesson-content-header">
        <SwitchButtons
          class="content-header-switch"
          v-model="isDrag"
          :items="tabsItems"
        />
        <div class="d-flex align-center">
          <v-btn
            depressed
            :disabled="isDrag"
            class="button-white-blue mr-4"
            @click="addBlock('title')"
          >
            <v-icon small> mdi-format-title </v-icon>
            <span>Заголовок</span>
          </v-btn>
          <v-btn
            depressed
            :disabled="isDrag"
            class="button-white-blue mr-4"
            @click="addBlock('text')"
          >
            <v-icon small> mdi-format-letter-case </v-icon>
            <span>Текст</span>
          </v-btn>
          <v-btn
            depressed
            :disabled="isDrag"
            class="button-white-blue mr-4"
            @click="addBlock('image')"
          >
            <v-icon small> mdi-image-outline </v-icon>
            <span>Изображение</span>
          </v-btn>
          <v-btn
            depressed
            :disabled="isDrag"
            class="button-white-blue mr-4"
            @click="addBlock('video')"
          >
            <v-icon small> mdi-play-box </v-icon>
            <span> Видео </span>
          </v-btn>
          <v-btn
            depressed
            :disabled="isDrag"
            class="button-white-blue mr-4"
            @click="addBlock('audio')"
          >
            <v-icon small> mdi-music-note </v-icon>
            <span> Аудио </span>
          </v-btn>
        </div>
      </div>

      <div class="lesson-content-blocks" :isDrag="isDrag">
        <draggable
          :list="blocks"
          tag="div"
          :draggable="isDrag ? `.lesson-block-item` : ''"
        >
          <!-- title -->
          <div
            class="lesson-block-item"
            v-for="(item, id) in blocks"
            :key="item.id"
          >
            <div v-if="isDrag" class="block-item-icon">
              <v-icon color="--app-grey-3">mdi-menu</v-icon>
            </div>
            <div v-else class="block-item-noticon"></div>
            <div class="lesson-content-blocks"></div>
            <div class="block-item-box" v-if="item.blockType == 'title'">
              <div class="item-box-title">
                <div class="title-text-block">Заголовок</div>
                <div @click="deleteBlock(item)" style="cursor: pointer">
                  <v-icon color="var(--app-grey-3)">mdi-delete-outline</v-icon>
                </div>
              </div>
              <!-- <TextInput
                :max="250"
                :maxLength="250"
                :disabled="isDrag"
                v-model="item.text"
                label="Текст заголовка"
              /> -->
              <CBigTextInput
                v-model="item.text"
                placeholder="Введите заголовок"
              ></CBigTextInput>
            </div>
            <div class="block-item-box" v-else-if="item.blockType == 'text'">
              <div class="item-box-title">
                <div class="title-text-block">Текстовый блок</div>
                <div @click="deleteBlock(item)" style="cursor: pointer">
                  <v-icon color="var(--app-grey-3)">mdi-delete-outline</v-icon>
                </div>
              </div>
              <trumbowyg
                :max="4999"
                :maxLength="4999"
                :disabled="isDrag"
                :config="config"
                v-model="item.text"
              >
              </trumbowyg>
              <div class="d-flex flex-row justify-space-between">
                <v-btn
                  @click="clearTags(['p', 'br', 'ol', 'ul', 'li', 'a'], item)"
                  class="button-white-blue mt-3 mr-5 mb-4"
                  :disabled="isDrag"
                  depressed
                >
                  <span>Очистить стили текста</span>
                </v-btn>
              </div>
            </div>
            <div class="block-item-box" v-else-if="item.blockType == 'image'">
              <div class="item-box-title">
                <div class="title-text-block">
                  <div class="media-block-header">
                    Изображение
                    <div @click="deleteBlock(item)" style="cursor: pointer">
                      <v-icon color="var(--app-grey-3)"
                        >mdi-delete-outline</v-icon
                      >
                    </div>
                  </div>
                  <div class="text-block-media">
                    <TextInput
                      valid
                      :max="250"
                      clearable
                      :maxLength="250"
                      :disabled="isDrag"
                      v-model="item.text"
                      label="Подпись к изображению"
                    />
                  </div>
                </div>
              </div>
              <!-- <ImageInput
                :disabled="isDrag"
                label="Файл изображения"
                mdiIcon="mdi-image-outline"
                v-model="item.file"
              /> -->
              <DragDropFile
                v-model="item.file"
                placeholder="Добавить фото"
                type="image"
                :allowedTypes="['image']"
              >
              </DragDropFile>
            </div>
            <div class="block-item-box" v-else-if="item.blockType == 'video'">
              <div class="item-box-title">
                <div class="title-text-block">
                  <div class="media-block-header">
                    Видео
                    <div @click="deleteBlock(item)" style="cursor: pointer">
                      <v-icon color="var(--app-grey-3)"
                        >mdi-delete-outline</v-icon
                      >
                    </div>
                  </div>
                  <div class="text-block-media">
                    <TextInput
                      valid
                      :max="250"
                      clearable
                      :maxLength="250"
                      :disabled="isDrag"
                      v-model="item.text"
                      label="Подпись к видео"
                    />
                  </div>
                </div>
              </div>
              <div style="display: flex; gap: 12px">
                <DragDropFile
                  v-model="item.file"
                  type="video"
                  @change="firstFrame(item.file, id)"
                  placeholder="Добавить видео"
                  :allowedTypes="['video']"
                ></DragDropFile>
                <DragDropFile
                  v-model="item.preview"
                  :key="item.previewShow"
                  placeholder="Добавить превью"
                  type="image"
                  :allowedTypes="['image']"
                ></DragDropFile>
                <DialogVideo
                  :src="srcItem(dialogVideoSrc)"
                  v-model="dialogVideo"
                />
              </div>
            </div>
            <div class="block-item-box" v-else-if="item.blockType == 'audio'">
              <div class="item-box-title">
                <div class="title-text-block">
                  <div class="media-block-header">
                    Аудио
                    <div @click="deleteBlock(item)" style="cursor: pointer">
                      <v-icon color="var(--app-grey-3)"
                        >mdi-delete-outline</v-icon
                      >
                    </div>
                  </div>
                  <div class="text-block-media">
                    <TextInput
                      valid
                      :max="250"
                      clearable
                      :maxLength="250"
                      :disabled="isDrag"
                      v-model="item.text"
                      label="Подпись к аудио"
                    />
                  </div>
                </div>
              </div>
              <div>
                <DragDropFile
                  v-model="item.file"
                  placeholder="Добавить аудио"
                  type="audio"
                  :allowedTypes="['audio']"
                ></DragDropFile>
              </div>
            </div>
          </div>
        </draggable>
      </div>

      <div class="lesson-content-footer">
        <v-btn depressed class="button-white-black mr-4" @click="resetValues">
          Отменить изменения
        </v-btn>
        <v-btn depressed class="button-blue" @click="sendValues">
          Cохранить изменения
        </v-btn>
      </div>
    </div>
    <div v-else class="loading-block">
      <v-progress-circular
        indeterminate
        :size="60"
        :width="4"
        color="var(--app-blue)"
      ></v-progress-circular>
    </div>
  </div>
</template>
<script>
import DialogVideo from "@/components/main/dialog/DialogVideo";
import TextInput from "@/components/main/inputs/TextInput.vue";
import SwitchButtons from "@/components/main/SwitchButtons";
import draggable from "vuedraggable";
import CBigTextInput from "../../components/main/inputs/CBigTextInput.vue";
import DragDropFile from "../../components/main/files/DragDropFile.vue";
export default {
  components: {
    TextInput,
    DialogVideo,
    draggable,
    SwitchButtons,
    CBigTextInput,
    DragDropFile,
  },
  props: {
    items: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data: () => ({
    isDrag: false,
    news: {},
    dialogVideoSrc: "",
    blocks: [],
    config: {
      lang: "ru",
      btns: [
        ["viewHTML"],
        ["strong", "em", "del"],
        ["superscript", "subscript"],
        ["link"],
        ["justifyLeft", "justifyCenter", "justifyRight", "justifyFull"],
        ["unorderedList", "orderedList"],
        ["horizontalRule"],
      ],
      removeformatPasted: true,
    },
    tabsItems: [
      { text: "Контент", value: false },
      { text: "Порядок", value: true },
    ],
    loading: false,
    dialogVideo: false,
  }),
  computed: {},
  methods: {
    placeVideoToDialog(srcVideo) {
      this.dialogVideo = true;
      this.dialogVideoSrc =
        typeof srcVideo === "string" ? srcVideo : URL.createObjectURL(srcVideo);
    },
    srcItem(value) {
      if (typeof value === "string") {
        return value;
      }
      if (value > 0 && typeof value === "object") {
        return window.URL.createObjectURL(value);
      }
    },
    clearTags(tagEnter, item) {
      const tags = [];
      let tag = "";
      for (let a = 0; a < tagEnter.length; a++) {
        tag = tagEnter[a].replace(/<|>/g, "").trim();
        if (tagEnter[a] === "br") tags.push(tag);
        else if (tagEnter[a].length > 0) tags.push(tag, `/${tag}`);
      }
      const re = new RegExp(`<(?!(${tags.join("|")})s*/?)[^>]+>`, "g");
      item.text = item.text.replace(re, "");
      item.text = item.text.replace(/\s*style=(["'])(.*?)\1/gim, "");
      item.text = item.text.replace(/\s*class=(["'])(.*?)\1/gim, "");
    },
    async getBlocks() {
      await this.$axios
        .get(`${this.$store.getters.apiV7}/news/${this.$route.params.id}`, {
          headers: {
            authorization: this.$store.getters.adminPanelHeaderAuth,
          },
        })
        .then((res) => {
          this.news = res.data;
          this.blocks = res.data.blocks.map((i) => {
            return i;
          });
          this.loading = true;
        })
        .catch(() => {
          this.$store.commit("errorSet", true);
          this.$store.commit("errorTextSet", "Ошибка при получении блоков");
        });
    },
    deleteBlock(item) {
      this.blocks = this.blocks.filter((i) => i.id !== item.id);
      this.news.blocks = this.news.blocks.filter((i) => i.id !== item.id);
    },
    addBlock(type) {
      if (type === "title") {
        this.blocks.push({
          blockType: "title",
          text: "",
          file: null,
          id: this.uuidv4(),
          sort: this.blocks.length + 1,
          isVisible: true,
          newsId: this.$route.params.id,
        });
      } else if (type === "text") {
        this.blocks.push({
          blockType: "text",
          text: "",
          file: null,
          id: this.uuidv4(),
          sort: this.blocks.length + 1,
          isVisible: true,
          newsId: this.$route.params.id,
        });
      } else if (type === "image") {
        this.blocks.push({
          blockType: "image",
          text: null,
          file: "",
          id: this.uuidv4(),
          sort: this.blocks.length + 1,
          isVisible: true,
          newsId: this.$route.params.id,
        });
      } else if (type === "video") {
        this.blocks.push({
          blockType: "video",
          text: null,
          file: "",
          id: this.uuidv4(),
          sort: this.blocks.length + 1,
          preview: "",
          previewShow: "",
          isVisible: true,
          newsId: this.$route.params.id,
        });
      } else if (type === "audio") {
        this.blocks.push({
          blockType: "audio",
          text: null,
          file: "",
          id: this.uuidv4(),
          sort: this.blocks.length + 1,
          isVisible: true,
          newsId: this.$route.params.id,
        });
      }
      setTimeout(() => {
        this.scrollToBottom();
      }, 100);
    },
    //uuid v4 generator
    uuidv4() {
      return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, (c) =>
        (
          +c ^
          (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (+c / 4)))
        ).toString(16)
      );
    },
    async saveFile(el, type) {
      const formData = new FormData();
      formData.append("file", el.file);
      formData.append("blockType", type);

      await this.$axios
        .post(`${this.$store.getters.apiV7}/upload`, formData, {
          headers: {
            authorization: this.$store.getters.adminPanelHeaderAuth,
          },
        })
        .then((res) => {
          el.file = res.data;
        })
        .catch(() => {
          this.$store.commit("errorSet", true);
          this.$store.commit("errorTextSet", "Ошибка при загрузке изображения");
          this.useFallback();
        });
    },

    async sendValues() {
      this.loading = false;
      this.news.blocks = this.blocks;
      this.news.blocks = this.news.blocks
        .map((v) => {
          if (v.blockType === "title" || v.blockType === "text") {
            if (v.text) {
              return v;
            }
          }
          if (
            v.blockType === "image" ||
            v.blockType === "audio" ||
            v.blockType === "video"
          ) {
            if (v.file) {
              return v;
            }
          }
        })
        .filter((v) => v);
      for (const el of this.news.blocks) {
        if (
          el.blockType === "image" &&
          el.file &&
          typeof el.file === "object"
        ) {
          await this.saveFile(el, "image");
        }
        if (el.preview && typeof el.preview === "object") {
          const elPreview = { file: el.preview };
          await this.saveFile(elPreview, "image");
          el.preview = elPreview.file;
        }
        if (
          el.blockType === "video" &&
          el.file &&
          typeof el.file === "object"
        ) {
          await this.saveFile(el, "video");
        }
        if (
          el.blockType === "audio" &&
          el.file &&
          typeof el.file === "object"
        ) {
          await this.saveFile(el, "audio");
        }
      }
      this.news.blocks = this.news.blocks.map((i, index) => {
        i.sort = index + 1;
        if (i.linkFile) {
          i.file = i.linkFile;
          delete i.linkFile;
        }
        return i;
      });

      this.$axios
        .patch(
          `${this.$store.getters.apiV7}/news/${this.$route.params.id}`,
          {
            id: this.$route.params.id,
            blocks: this.news.blocks,
          },
          {
            headers: {
              authorization: this.$store.getters.adminPanelHeaderAuth,
            },
          }
        )
        .then(() => {
          this.getBlocks();
          this.$store.commit("successSet", true);
          this.$store.commit("successTextSet", "Блоки успешно сохранены");
        })
        .catch((err) => {
          this.$store.commit("errorSet", true);
          if (err.response.data.errors[0].code === "too_big") {
            switch (err.response.data.errors[0].path[2]) {
              case "title":
                this.$store.commit(
                  "errorTextSet",
                  "Размер заголовка должен быть не более 250 символов"
                );
                break;
              case "text":
                this.$store.commit(
                  "errorTextSet",
                  "Размер текста должен быть не более 5000 символов"
                );
                break;
              case "image":
                this.$store.commit(
                  "errorTextSet",
                  "Размер фото должен быть не более 50 Мб"
                );
                break;
              case "video":
                this.$store.commit(
                  "errorTextSet",
                  "Размер видео должен быть не более 1 Гб"
                );
                break;
              case "audio":
                this.$store.commit(
                  "errorTextSet",
                  "Размер аудиофайла должен быть не более 100 Мб"
                );
                break;
              default:
                this.$store.commit(
                  "errorTextSet",
                  "Ошибка при обновлении данных"
                );
                break;
            }
          }
          this.useFallback();
        });
    },
    async firstFrame(videoFile, id) {
      const urlVideo = URL.createObjectURL(videoFile);
      const firstFrameOfVideo = await this.takeFirstFrameVideo(urlVideo);
      this.blocks[id].preview = firstFrameOfVideo;
      this.blocks[id].previewShow = URL.createObjectURL(firstFrameOfVideo);
    },
    useFallback() {
      setTimeout(() => {
        this.getBlocks();
      }, 3000);
    },

    resetValues() {
      this.getBlocks();
    },

    async scrollToBottom() {
      const slide = document.querySelector(".lesson-content-blocks");
      const count = 100;
      const startTop = slide.scrollHeight;
      for (let i = 0; i < count; i++) {
        await new Promise((resolve) => {
          setTimeout(() => {
            resolve();
          }, 500 / count);
        });
        slide.scrollTop += startTop / count;
      }
    },
  },

  created() {
    this.getBlocks();
  },
  watch: {},
};
</script>
<style lang="scss" scoped>
.lesson-content {
  height: calc(100vh - 156px);
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  .lesson-content-header {
    height: 64px;
    position: sticky;
    display: flex;
    align-items: center;
    background: var(--app-grey-1);
    padding: 0px 64px;
    .content-header-switch {
      margin-right: 64px;
    }
  }
  .lesson-content-blocks {
    overflow: auto;
    height: calc(100% - 128px);
    padding: 8px 0px;
  }
  .lesson-content-footer {
    position: sticky;
    height: 64px;
    background: var(--app-grey-2);
    display: flex;
    align-items: center;
    padding: 0px 64px;
  }
}

.lesson-block-item {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  width: 100%;
  min-width: 550px;
  margin-bottom: 24px;
  .block-item-icon {
    margin: 0px 16px 0px 24px;
    cursor: pointer;
  }
  .block-item-noticon {
    min-width: 64px;
    max-width: 64px;
  }
  .block-item-box {
    width: 100%;
    padding-right: 64px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap: 8px;

    .item-box-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      // margin-bottom: 16px;
      user-select: none;
      .title-text-block {
        color: var(--app-black);
        font-size: 16px;
        font-weight: 600;
        display: flex;
        flex-direction: column;
        gap: 16px;
        width: 100%;

        .media-block-header {
          display: flex;
          justify-content: space-between;
        }
        & ::v-deep .text-block-media {
          font-weight: 400 !important;
          font-size: 16px;
          font-style: normal;
          margin-bottom: -6px;
        }
      }
    }
  }
}
.loading-block {
  width: 100%;
  height: calc(100vh - 150px);
  display: flex;
  align-items: center;
  justify-content: center;
}

.test-lessons-block {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background: var(--app-grey-1);
  padding: 32px 64px;
  .lessons-block-title {
    display: flex;
    align-items: center;
    margin-bottom: 32px;
  }
}
</style>
