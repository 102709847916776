<template>
  <input
    class="big-text-input"
    @input="toggleInput"
    :value="value"
    :placeholder="placeholder"
    type="text"
  />
</template>

<script>
export default {
  props: {
    value: { type: String, default: "" },
    placeholder: { type: String, default: "" },
  },
  methods:{
    toggleInput(e){
      this.$emit('input', e.target.value)
    }
  }
};
</script>

<style lang="scss" scoped>
.big-text-input {
  font-size: 32px;
  height: 38px;
  width: 100%;
  font-weight: 700;
  line-height: 38.34px;
  text-align: left;
  border: none;
  outline: none;
  &::placeholder{
    color: #9B9EA3;
  }
}
</style>
