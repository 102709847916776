<template>
  <div class="page-container" v-if="loading">
    <div
      class="container-main-block"
      :style="tab === 0 ? `height: calc(100vh - 64px)` : 'height: 100vh'"
    >
      <div class="tooltip">
        <div class="main-block-title pl-16 pr-16">
          {{ item.title }}
        </div>
        <span class="tooltiptext">{{ item.title }}</span>
      </div>
      <v-tabs
        class="pl-16 pr-16"
        v-model="tab"
        background-color="var(--app-grey-1)"
        color="var(--app-blue)"
      >
        <v-tabs-slider color="var(--app-blue)"></v-tabs-slider>

        <v-tab>
          <v-icon class="mr-2" size="18">mdi-information</v-icon>

          <div class="tab-text">Информация</div>
        </v-tab>

        <v-tab @click="getValuesContent">
          <v-icon class="mr-2" size="18">mdi-view-split-vertical</v-icon>

          <div class="tab-text">Содержание</div>
        </v-tab>
      </v-tabs>

      <v-divider style="margin: 0 64px"></v-divider>

      <div class="main-block-items">
        <v-tabs-items v-model="tab">
          <v-tab-item :value="0" style="height: 100%">
            <NewsInformation
              style="overflow-y: auto"
              ref="newsInformation"
              :item="item"
              :topics="topics"
            />
          </v-tab-item>

          <v-tab-item :value="1">
            <NewsContent ref="NewsContent" :item="item" />
          </v-tab-item>
        </v-tabs-items>
      </div>
    </div>

    <div class="container-bottom-block" v-if="tab === 0">
      <div>
        <v-btn
          depressed
          :disabled="send"
          @click="getItem"
          class="button-white-black mr-4"
        >
          Отменить изменения
        </v-btn>

        <v-btn depressed :loading="send" class="button-blue" @click="sendItem">
            Cохранить изменения
        </v-btn>
      </div>

      <div>
        <v-btn
          depressed
          :disabled="send"
          class="button-white-red"
          @click="dialogDelete = true"
        >
          Удалить новость
        </v-btn>
      </div>
    </div>

    <DialogConfirm
      v-model="dialogDelete"
      titleText="Удаление новости"
      :mainText="`Вы уверены что хотите удалить  ${
        item ? `${truncatedTitle}` : 'новость'
      }?`"
      @click:delete="deleteItem"
    >
    </DialogConfirm>
  </div>
</template>
<script>
import DialogConfirm from "@/components/main/dialog/DialogConfirm";
import NewsInformation from "./NewsInformation.vue";
import NewsContent from "./NewsContent.vue";

export default {
  components: {
    DialogConfirm,
    NewsInformation,
    NewsContent,
  },
  data: () => ({
    item: {},
    topics: [],
    tests: [],
    statistics: {},
    loading: false,
    tab: 0,
    dialogDelete: false,
    send: false,
  }),
  computed: {
    truncatedTitle() {
      return this.wordTruncation(this.item.title, 0, 30);
    },
  },
  methods: {
    async getItem() {
      await this.$axios
        .get(`${this.$store.getters.apiV7}/news/${this.$route.params.id}`, {
          headers: {
            authorization: this.$store.getters.adminPanelHeaderAuth,
          },
        })
        .then((res) => {
          this.item = res.data;
        })
        .catch((err) => {
          this.$store.commit("errorSet", true);
          this.$store.commit("errorTextSet", err);
        });
    },

    getAllValues() {
      Promise.all([this.getItem()]).then(() => {
        this.loading = true;
      });
    },

    async saveImage(el) {
      const formData = new FormData();
      if (typeof el.photo === "object") {
        formData.append("file", el.photo);
      } else {
        return;
      }

      await this.$axios
        .post(`${this.$store.getters.apiV7}/upload`, formData, {
          headers: {
            authorization: this.$store.getters.adminPanelHeaderAuth,
          },
        })
        .then((res) => {
          el.photo = res.data;
        })
        .catch((err) => {
          this.$store.commit("errorSet", true);
          this.$store.commit("errorTextSet", err);
        });
    },

    async getTopics() {
      await this.$axios
        .get(
          `${this.$store.getters.apiV7}/topics?${new URLSearchParams({
            page: 1,
            take: 9999,
          })}`,
          {
            headers: {
              authorization: this.$store.getters.adminPanelHeaderAuth,
            },
          }
        )
        .then((res) => {
          this.topics = res.data.data;
        })
        .catch((err) => {
          this.$store.commit("errorSet", true);
          this.$store.commit("errorTextSet", err);
        });
    },

    async sendItem() {
      const item = await this.$refs.newsInformation.sendItems();
      const clearTopics = [];

      for (const el of item.topics) {
        if (typeof el === "object") {
          clearTopics.push(el.id);
        } else {
          clearTopics.push(el);
        }
      }
      item.topics = clearTopics;
      await this.saveImage(item);
      if (item) {
        this.send = true;
        this.$axios
          .patch(
            `${this.$store.getters.apiV7}/news/${this.$route.params.id}`,
            {
              id: item.id,
              title: item.title,
              photo: item.photo || "",
              description: item.description || "",
              isPublished: item.isPublished || false,
              isSendPush: item.isSendPush || false,
              groups: item.groups || [],
              topics: item.topics || [],
            },
            {
              headers: {
                authorization: this.$store.getters.adminPanelHeaderAuth,
              },
            }
          )
          .then(() => {
            this.$store.commit("successSet", true);
            this.$store.commit(
              "successTextSet",
              "Измененения успешно сохранены"
            );
            this.send = false;
            this.getAllValues();
          })
          .catch((err) => {
            this.send = false;
            this.$store.commit("errorSet", true);
            if (err.response.data.errors[0].code === "too_big") {
              switch (err.response.data.errors[0].type) {
                case "string":
                  this.$store.commit(
                    "errorTextSet",
                    "Размер заголовка должен быть не более 250 символов, текста не более 4000 символов"
                  );
                  break;
                case "object":
                  this.$store.commit(
                    "errorTextSet",
                    "Размер фото должен быть не более 50 Мб"
                  );
                  break;
                default:
                  this.$store.commit(
                    "errorTextSet",
                    "Ошибка при обновлении новости"
                  );
                  break;
              }
            }
            this.getAllValues();
          });
      }
    },
    deleteItem() {
      this.$axios
        .delete(`${this.$store.getters.apiV7}/news/${this.$route.params.id}`, {
          headers: {
            authorization: this.$store.getters.adminPanelHeaderAuth,
          },
        })
        .then(() => {
          this.$router.push({ name: "NewsArticles" });
        })
        .catch((err) => {
          this.$store.commit("errorSet", true);
          this.$store.commit("errorTextSet", err);
        });
    },
    getValuesContent() {
      if (this.$refs.courseContent) {
        this.$refs.courseContent.getItems();
      }
    },
  },
  created() {
    this.getAllValues();
    this.getTopics();
  },
};
</script>
<style lang="scss" scoped>
.page-container {
  height: 100%;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  .container-main-block {
    display: flex;
    flex-direction: column;
    .main-block-title {
      cursor: default;
      max-height: 64px;
      max-width: 1132px;
      margin-top: 40px;
      margin-bottom: 20px;
      font-size: 28px;
      font-weight: 600;
      color: var(--app-black);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .main-block-items {
      height: 100%;
      overflow-y: auto;
    }
  }
  .container-bottom-block {
    position: sticky;
    height: 64px;
    background: var(--app-grey-2);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 64px;
  }
}

.tooltip {
  position: relative;
  display: inline-block;
  max-width: fit-content;
  height: fit-content;
  text-wrap: wrap;
  word-break: break-word;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: fit-content;
  background-color: var(--app-black);
  color: var(--app-white);
  opacity: 0.9;
  text-align: center;
  padding: 5px 15px;
  border-radius: 6px;
  position: absolute;

  z-index: 90;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
}

.tooltip .tooltiptext::after {
  content: " ";
  position: absolute;
  bottom: 100%; /* At the top of the tooltip */
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent var(--app-black) transparent;
}

@keyframes ani {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.9;
  }
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  animation: ani 0.5s ease-in;
}
</style>
